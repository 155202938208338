@import '../../styles/breakpoints.scss';

.about-me {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include desktop-up {
        flex-direction: row;
    }
}

.about-me-text {
    margin-left: 4rem;
    margin-right: 4rem;
}

.cricket-photo {
    background-image: url('../../assets/cricket.gif');
    background-position: 50% 62%;
    background-size: cover;
    height: 15rem;
    border-radius: 1rem;
    margin: 2rem;
}

.selfie {
    min-width: 25rem;
    height: 25rem;
    margin: 2rem;

    background-image: url('../../assets/aboutme-selfie.jpg');
    background-position: 0% 10%;
    background-size: cover;
    transform: scaleX(-1);

    border-radius: 1rem;
}
