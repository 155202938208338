@import '../../styles/styles.scss';

.social-media-links {
    font-size: 2rem;
}

.social-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.dark-social-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: $grey-light;
}

.resume-button {
    text-align: right;
}

.footer-right {
    text-align: right;
    font-size: 1.5rem;
}

.social-icon:hover {
    color: $grey-darker !important;
}

.dark-social-icon:hover {
    color: $grey-lighter !important;
}