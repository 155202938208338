@import '../../styles/breakpoints.scss';
@import '../../styles/styles.scss';

.splash {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.splash-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    text-align: center;
    @include desktop-up {
        flex-direction: row;
        text-align: left;
        justify-content: space-around;
    }
}

.arrow-down {
    text-align: center;
    font-size: 2rem;
    padding: 1rem 0;
}

.portrait {
    width: 15rem;
    height: 15rem;
    margin: 2rem;

    background-image: url("../../assets/selfie.jpg");
    background-position: center;
    background-size: cover;

    border-radius: 1rem;
}

.button-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include desktop-up {
        flex-direction: row;
    }
}

.vertical-line {
    margin: 0.5rem 1rem;
    align-self: stretch;
}

.theme-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}