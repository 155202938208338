@mixin phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin tablet-down {
  @media (max-width: 1024px) { @content; }
}

@mixin desktop-up {
  @media (min-width: 1024px) { @content; }
}
@mixin big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
