@import '../styles/breakpoints.scss';
@import '../styles/styles.scss';

html {
    @include phone-only {
        font-size: 12px !important;
    }
}

.body-section {
    padding-top: 7rem;
    padding-bottom: 7rem;
    @include desktop-up {
        min-height: 100vh;
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }
}
#knowledge {
    background-image: url('../assets/knowledge-pattern.png');
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 60%;
    @include desktop-up {
        background-size: 20%;
    }
    background-position: center;
}

#contact-me {
    background-image: url('../assets/contact-pattern.png');
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 50%;
    @include desktop-up {
        background-size: 12.5%;
    }
    background-position: center;
}
