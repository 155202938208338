@import '../../../styles/breakpoints.scss';

.cover-photo {
    background-position: 50% 50%;
    background-size: cover;
    height: 20rem;
}

.job-card {
    margin: 2rem 2rem;
    @include desktop-up {
        max-width: 50vw;
    }
}

.company-logo {
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    background-color: white;
}

.company-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
}

.title-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
