@charset "utf-8";

// Update Bulma's global variables
$family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

$link: hsl(0, 0%, 29%);
$white-ter: hsl(0, 0%, 98%);
$link-blue: hsl(217, 71%, 53%);

$message-radius: 1rem;
$message-header-radius: 1rem;
$message-body-border-width: 0;

// Import only what you need from Bulma
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/elements/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/components/navbar.sass';
@import '../../node_modules/bulma/sass/layout/_all.sass';
@import '../../node_modules/bulma-divider/dist/css/bulma-divider.sass';
// Special Light Styles
* {
    transition: background-color 0.4s ease-in-out 0s;
}

.underline {
    color: $info !important;
    transition: padding-bottom 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
    border-bottom: 1px solid $info;
    padding-bottom: 1px;
    white-space: nowrap;
}

.underline:hover {
    color: $link-blue !important;
    border-bottom: 2px solid $link-blue;
    padding-bottom: 2px;
}

// Dark Styles
.dark-title {
    color: $grey-lighter !important;
}

.dark-subtitle {
    color: $grey-lighter !important;
}

.dark-underline {
    color: $grey-lighter !important;
    transition: padding-bottom 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
    border-bottom: 1px solid $grey-lighter;
    padding-bottom: 1px;
    white-space: nowrap;
}

.dark-underline:hover {
    color: $grey-light !important;
    border-bottom: 2px solid $grey-light;
    padding-bottom: 2px;
}

.dark-text {
    color: $grey-lighter !important;
}

.dark-navbar-item {
    color: $grey-lighter !important;
}

.dark-navbar-item:hover {
    background-color: $grey-dark !important;
}

.dark-with-center {
    background: $black-ter !important;
    border-radius: 16px;
}

.dark-layer-1 {
    background-color: $grey-darker;
}

.dark-background {
    background-color: $black-ter;
}
