.nav-items {
    align-self: center;
}

.contact-button {
    align-self: center;
    margin-left: 0.5rem;
}

.navbar-item {
    text-align: center;
}