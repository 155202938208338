@import '../../styles/breakpoints.scss';

.knowledge-container {
    border: 1px solid #cde9ff;
    border-radius: 1rem;
    padding: 2rem !important;
    margin-left: 16rem;
    margin-right: 16rem;
    @include tablet-down {
        margin: 2rem !important;
    }
}

.with-center {
    background-color: white;
}

.tags {
    margin-bottom: 1rem !important;
    margin-left: 2rem;
}

.tag {
    font-size: 1rem !important;
}

.category {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.sub-category-title {
    margin-left: 1rem;
}

.skill {
    transition: 0.2s !important;
    cursor: default;
}

.skill:hover {
    background-color: #cde9ff !important;
}