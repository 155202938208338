@import '../../styles/breakpoints.scss';
@import '../../styles/styles.scss';

.navbar {
    padding: 0.5rem;
    @include desktop-up {
        padding: 1rem;
    }
}

.drop-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.096);
    background-color: hsl(0, 0%, 98%);
    transition: box-shadow 0.1s ease-in-out;
}

.navbar-burger {
    display: flex;
    align-items: center;
    justify-content: center;
}

.exit-icon {
    text-align: center;
}

.logo-container {
    display: flex;
    align-items: center !important;
}

.logo-with-text {
    display: none;
    align-items: center;
}

.make-visible {
    display: flex !important;
    transition: display 0.1s ease-in-out;
}

.logo {
    border-radius: 0.4rem;
    height: 3rem;
    width: 3rem;
    @include desktop-up {
        height: 2rem;
        width: 2rem;
    }

    background-image: url('../../assets/logo.jpg');
    background-position: center;
    background-size: 125%;
    margin-right: 1rem;
}

.name-text {
    color: $text;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.position-text {
    color: $text;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
}